import { createTheme, colors } from '@material-ui/core';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: `#00b2c9`,
      light: `#69ccd9`
    },
    secondary: {
      main: `#2e2e7a`,
    },
    link: {
      main: `#00b2c9`
    },
    accent: {
      main:`#8f4a9e`
    },
    error: {
      main: colors.red.A400,
    },
    background: {
      default: `#fff`,
    }
  },
  typography: {
    h1: {
      fontSize: "3.8rem",
      fontWeight: 700,
      color: "white"
    },
    h2: {
      fontSize: "2.8rem",
      fontWeight: 600,
      color: "white"
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '1.5em',
    
    }
  },
  overrides: {
    MuiButton: {
      text: {
        fontSize:"1.0625rem",
        color: 'inherit',
      },
    },
  },
  
});

export default theme;
