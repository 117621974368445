import React, {useReducer} from "react"

const defaultState = {
    screening2: "",
    screening3: "",
    screening4: "",
    diagnostic1: "",
}

const QuestionnaireContext = React.createContext(defaultState);

const QuestionnaireProvider = (props) => {
    const [questionnaireData, setQuestionnaireData] = useReducer((state, newState) => ({...state, ...newState}),{
        screening2: "",
        screening3: "",
        screening4: "",
        diagnostic1: "",
    })



    return (
        <QuestionnaireContext.Provider value={[questionnaireData, setQuestionnaireData]}>
            {props.children}
        </QuestionnaireContext.Provider>
    );
}

export { QuestionnaireContext, QuestionnaireProvider };