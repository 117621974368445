import React from "react";
import ReactDOM from 'react-dom';

import { QuestionnaireProvider } from "./src/context/questionnaireContext";
import { AppInsightsContextProvider } from "./src/context/AppInsightsContext";
import { appInsights } from './src/appInsights';

export const onRouteUpdate = ({ location }) => {
  appInsights.trackPageView({
    name: location.pathname,
    uri: location.pathname,
  });
};

export const wrapRootElement = ({element}) => (
    <AppInsightsContextProvider>
      <QuestionnaireProvider>
          {element}
      </QuestionnaireProvider>
    </AppInsightsContextProvider>
)

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}