import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=e2c84e5c-b783-4629-aa8e-feb3edd5f63b',
    extensions: [reactPlugin],
    enableCorsCorrelation: true,
    disableFetchTracking: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
    enableAutoRouteTracking: true,
  },
});

ai.loadAppInsights();

export default (Component) => withAITracking(reactPlugin, Component);
export const { appInsights } = ai;
export { reactPlugin };

